@import url(https://fonts.googleapis.com/css?family=Cabin);
/*--------------------------
Call To Action Wrapper 
-----------------------------*/
.call-to-action {
    background: linear-gradient(145deg, #f81f01 0%, #ee076e 100%);
    position: relative;
    z-index: 2;
    &::before {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-image: url(/assets/images/pattern-1.png);
        content: "";
        z-index: -1;
        opacity: 0.5;
    }
    .inner {
        > span {
            color: #fff;
            font-size: 15px;
            text-transform: uppercase;
            letter-spacing: 4px;
            display: block;
            margin-bottom: 9px;
        }
        h2 {
            color: #ffffff;
            font-size: 75px;
            font-weight: 900;
            font-family: 'Montserrat', sans-serif;
            line-height: 90px;
            @media #{$sm-layout} {
                font-size: 47px;
                line-height: 70px;
            }
        }
        a {
            &.rn-button-style--2 {
                margin-top: 30px;
                &:hover {
                    background: #ffffff;
                    border-color: #ffffff;
                    color: $theme-color;
                }
            }
        }
    }
    &.bg_image {
        &::before {
            display: none;
        }
    }
}






@mixin bgGradient {
    background:
      $colorOutlineFade
      linear-gradient(
        transparent 0%,
        transparent 50%,
        $colorOutline 50%,
        $colorOutline 100%
      );
  }

$colorBg: #222430;
$colorOutline: #ff0000;
$colorOutlineFade: #4e5559;

$widthMouse: 42px;
$heightMouse: 78px;
$borderMouse: 6px;

$posMouse: 8px;
$posText: 2px;

$sizeTrackball: 10px;
$posTrackball: 20px;
$shrinkTrackball: 0.4;

$animDuration: 8s;


.mouse {
@include bgGradient;
  position: relative;
  width: $widthMouse;
  height: $heightMouse;
  border-radius: 100px;
  background-size: 100% 200%;
  animation: 
    colorSlide $animDuration linear infinite,
    nudgeMouse $animDuration ease-out infinite;
  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    margin: auto;
  }
  &:before {
    width: $widthMouse - $borderMouse;
    height: $heightMouse - $borderMouse;
    background-color: $colorBg;
    border-radius: 100px;
  }
  &:after {
    background-color: $colorOutline;
    width: $sizeTrackball;
    height: $sizeTrackball;
    border-radius: 100%;
    animation: trackBallSlide $animDuration linear infinite;
  }
}

@keyframes colorSlide { 
  0% { background-position: 0% 100%; }
  20% { background-position: 0% 0%; }
  21% { background-color: $colorOutlineFade; }
  29.99% { 
    background-color: $colorOutline;
    background-position: 0% 0%;
  }
  30% { 
    background-color: $colorOutlineFade;
    background-position: 0% 100%;
  }
  50% { background-position: 0% 0%; }
  51% { background-color: $colorOutlineFade; }
  59% { 
    background-color: $colorOutline;
    background-position: 0% 0%;
  }
  60% { 
    background-color: $colorOutlineFade;
    background-position: 0% 100%;
  }
  80% { background-position: 0% 0%; }
  81% { background-color: $colorOutlineFade; }
  90%, 100% { background-color: $colorOutline; }
}

@keyframes trackBallSlide {
  0% {
    opacity: 1;
    transform: scale(1) translateY(-$posTrackball);
  }
  6% { 
    opacity: 1;
    transform: scale(0.9) translateY($posTrackball/4);
  }
  14% {
    opacity: 0;
    transform: scale($shrinkTrackball) translateY($posTrackball*2);
  }
  15%, 19% {
    opacity: 0;
    transform: scale($shrinkTrackball) translateY(-$posTrackball);
  }
  28%, 29.99% {
    opacity: 1;
    transform: scale(1) translateY(-$posTrackball);
  }
  30% {
    opacity: 1;
    transform: scale(1) translateY(-$posTrackball);
  }
  36% { 
    opacity: 1;
    transform: scale(0.9) translateY($posTrackball/4);
  }
  44% {
    opacity: 0;
    transform: scale($shrinkTrackball) translateY($posTrackball*2);
  }
  45%, 49% {
    opacity: 0;
    transform: scale($shrinkTrackball) translateY(-$posTrackball);
  }
  58%, 59.99% {
    opacity: 1;
    transform: scale(1) translateY(-$posTrackball);
  }
  60% {
    opacity: 1;
    transform: scale(1) translateY(-$posTrackball);
  }
  66% { 
    opacity: 1;
    transform: scale(0.9) translateY($posTrackball/4);
  }
  74% {
    opacity: 0;
    transform: scale($shrinkTrackball) translateY($posTrackball*2);
  }
  75%, 79% {
    opacity: 0;
    transform: scale($shrinkTrackball) translateY(-$posTrackball);
  }
  88%, 100% {
    opacity: 1;
    transform: scale(1) translateY(-$posTrackball);
  }
}

@keyframes nudgeMouse {
  0% { transform: translateY(0); }
  20% { transform: translateY($posMouse); }
  30% { transform: translateY(0); }
  50% { transform: translateY($posMouse); }
  60% { transform: translateY(0); }
  80% { transform: translateY($posMouse); }
  90% { transform: translateY(0); }
}

@keyframes nudgeText {
  0% { transform: translateY(0); }
  20% { transform: translateY($posText); }
  30% { transform: translateY(0); }
  50% { transform: translateY($posText); }
  60% { transform: translateY(0); }
  80% { transform: translateY($posText); }
  90% { transform: translateY(0); }
}

@keyframes colorText { 
  21% { color: $colorOutlineFade; }
  30% { color: $colorOutline; }
  51% { color: $colorOutlineFade; }
  60% { color: $colorOutline; }
  81% { color: $colorOutlineFade; }
  90% { color: $colorOutline; }
}



